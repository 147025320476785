import type { ProductListFilterSelectProps } from "../filter-types";
import type { ProductListFilterDialogFilter } from "../product-list-filter-dialog";
import BaseProductListFilterSelect from "./base-product-list-filter-select";
import BooleanProductListFilterSelect from "./boolean-product-list-filter-select";
import StoreStockProductListFilterSelect from "./store-stock-product-list-filter-select";

export enum AlgoliaFacetToFilter {
  PRICERANGE = "rrpPrice",
  ONSALE = "onSale",
  SUMMARY = "summary",
  BRANDNAME = "brand.name",
  ONCLEARNCE = "onClearnce",
  ONMEMBERPRICE = "onMemberPrice",
  STORESTOCK = "storeStock.store",
  AVAILABLEFORDELIVERY = "availableForDelivery",
  CATEGORIES = "categories.name",
}

const FilterComponentMapping = {
  [AlgoliaFacetToFilter.ONSALE]: BooleanProductListFilterSelect,
  [AlgoliaFacetToFilter.ONCLEARNCE]: BooleanProductListFilterSelect,
  [AlgoliaFacetToFilter.ONMEMBERPRICE]: BooleanProductListFilterSelect,
  [AlgoliaFacetToFilter.AVAILABLEFORDELIVERY]: BooleanProductListFilterSelect,
  [AlgoliaFacetToFilter.STORESTOCK]: StoreStockProductListFilterSelect,
};

export const AlgoliaFacetLabelToDisplayLabel = {
  [AlgoliaFacetToFilter.PRICERANGE]: "Filter By Price Range",
  [AlgoliaFacetToFilter.ONSALE]: "Filter By Sale",
  [AlgoliaFacetToFilter.SUMMARY]: "Summary",
  [AlgoliaFacetToFilter.BRANDNAME]: "Filter By Brand",
  [AlgoliaFacetToFilter.ONCLEARNCE]: "Filter By Clearance",
  [AlgoliaFacetToFilter.ONMEMBERPRICE]: "Filter By Member Price",
  [AlgoliaFacetToFilter.STORESTOCK]: "Filter by Current Store Availability",
  [AlgoliaFacetToFilter.AVAILABLEFORDELIVERY]: "Filter by Delivery Eligibility",
  [AlgoliaFacetToFilter.CATEGORIES]: "Filter By Category",
};

export const RrpPriceRanges = [
  { label: "$0 - $49", value: "0-49", hitCount: 0 },
  { label: "$50 - $99", value: "50-99", hitCount: 0 },
  { label: "$100 - $149", value: "100-149", hitCount: 0 },
  { label: "$150 - $199", value: "150-199", hitCount: 0 },
  { label: "$200 - $249", value: "200-249", hitCount: 0 },
  { label: "$250 & MORE", value: "250-0", hitCount: 0 },
];

export const mapAttributeIdToValue = (
  filterAttributeId: string,
  value: boolean | string,
) => {
  let mappedValue: boolean | string = "";
  switch (filterAttributeId) {
    case AlgoliaFacetToFilter.AVAILABLEFORDELIVERY: {
      mappedValue = "Eligible for Delivery";
      break;
    }
    case AlgoliaFacetToFilter.ONMEMBERPRICE: {
      mappedValue = "Member Price";
      break;
    }
    case AlgoliaFacetToFilter.ONSALE: {
      mappedValue = "On Sale";
      break;
    }
    case AlgoliaFacetToFilter.ONCLEARNCE: {
      mappedValue = "On Clearance";
      break;
    }
    case AlgoliaFacetToFilter.STORESTOCK: {
      mappedValue = "Available at current store";
      break;
    }
    case AlgoliaFacetToFilter.PRICERANGE: {
      mappedValue = RrpPriceRanges.filter(
        priceRangeValue => priceRangeValue.value === value,
      )[0].label;
      break;
    }
    default: {
      mappedValue = value;
      break;
    }
  }
  return mappedValue;
};

const ProductListFilterSelectFactory = ({
  filter,
  ...rest
}: ProductListFilterSelectProps & {
  filter: ProductListFilterDialogFilter;
}) => {
  if (
    FilterComponentMapping[
      filter.attributeId as keyof typeof FilterComponentMapping
    ]
  ) {
    const Component =
      FilterComponentMapping[
        filter.attributeId as keyof typeof FilterComponentMapping
      ];
    return <Component {...rest} />;
  }

  const Component = BaseProductListFilterSelect;
  return <Component {...rest} />;
};

export default ProductListFilterSelectFactory;
