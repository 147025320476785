import { Checkbox } from "~/components/ui/input";
import { P } from "~/components/ui/text";

import type { ProductListFilterSelectComponent } from "../filter-types";
import useProductListFilterSelect from "../hooks/use-product-list-filter-select";
import ProductListFilterHeader from "./product-list-filter-header";
import { mapAttributeIdToValue } from "./product-list-filter-select-factory";

export enum ProductListFilterDialogFilterRenderEnum {
  Base = "base",
  Boolean = "boolean",
  StoreStock = "storeStock",
}

const BooleanProductListFilterSelect: ProductListFilterSelectComponent = ({
  filterName,
  filterAttributeId,
  selectedValues = [],
  values = [],
  clearSelection,
  expanded,
}) => {
  const {
    onFilterSelect,
    toggleExpand,
    isExpanded,
    contentHeight,
    contentRef,
    filteredValues,
  } = useProductListFilterSelect({
    expanded,
    values,
  });

  // if values are only false, we don't need to display this filter
  const containsOnlyFalse = filteredValues.every(
    value => value.value === "false",
  );
  if (containsOnlyFalse) {
    return;
  }

  return (
    <div key={filterName} className="w-full">
      <div className="h-fit w-full">
        <ProductListFilterHeader
          filterName={filterName}
          selectedValues={selectedValues}
          filterAttributeId={filterAttributeId}
          isExpanded={isExpanded}
          displaySelectedValues={false}
          toggleExpand={toggleExpand}
          clearSelection={clearSelection}
        />
        <div
          className="overflow-hidden pt-[22px] transition-all duration-500 ease-in-out"
          style={{
            height: isExpanded ? `${contentHeight}px` : "0px",
          }}
        >
          <div ref={contentRef} className="">
            <div className="pb-3 pt-3">
              {filteredValues.map(value => {
                if (value.value === "true") {
                  const isSelected = selectedValues.includes(
                    decodeURIComponent(value.value),
                  );
                  return (
                    <div
                      key={value.value}
                      className="grid cursor-pointer grid-cols-[32px_auto] items-center gap-[8px] pt-2"
                    >
                      <Checkbox
                        onChange={() => {
                          onFilterSelect(filterAttributeId, value);
                        }}
                        iconSize={25}
                        checked={isSelected}
                      />
                      <P className="flex-grow">
                        {mapAttributeIdToValue(filterAttributeId, value.value)}{" "}
                        {`(${value.count})`}
                      </P>
                    </div>
                  );
                }
              })}
              {filteredValues.length === 0 ? (
                <P className="pl-[8px]">No results found.</P>
              ) : null}
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-[100%] bg-neutral-7"></div>
    </div>
  );
};

export default BooleanProductListFilterSelect;
