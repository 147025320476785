import { memo, useCallback, useMemo, useRef } from "react";
import { Toaster } from "react-hot-toast";

import type { ShouldRevalidateFunction } from "@remix-run/react";
import {
  Outlet,
  defer,
  useLocation,
  useMatches,
  useRouteLoaderData,
} from "@remix-run/react";

import { SWR } from "@commerce/.server/caching.server";
import {
  commitSession,
  getSessionServerContext,
  optionalAuthenticatedUser,
} from "@commerce/.server/sessions.server";
import { getTimingContext } from "~/commerce-sap/.server/timing.server";
import { getClient } from "~/contentful/.server/contentful.server";
import { deferDataRequest } from "~/lib/utils-server/http.server";

import { useContentfulLiveUpdates } from "@contentful/live-preview/react";

import { CATALOG_CONFIG } from "~/commerce-sap/.server/config";
import { Meganav } from "~/components/meganav/meganav";
import type { TypeConfigurationTemplateSkeleton } from "~/contentful/compiled";
import { ConsentBanner } from "~/contentful/components/consent-banner";
import { Footer } from "~/contentful/components/footer";
import { LAYOUT_CONFIG_NAME, LAYOUT_CONFIG_TYPE } from "~/contentful/contents";
import { MediaScreenProvider } from "~/contexts/madia-screen";
import { CategoryProvider } from "~/contexts/mobile-menu";
import {
  EmarsysContext,
  SendEmarsysEvents,
} from "~/emarsys/send-emarsys-events";
import { PrefetchPageAnchors } from "~/hooks/use-custom-delegated-anchors";
import { AU_STATE_CODE_NAMES_MAP } from "~/lib/constants";
import { env as _env } from "~/lib/env";
import { invariant } from "~/lib/utils/utils";
import LoginFlash from "~/routes/($locale)+/_auth+/components/login-flash";
import { BasketProvider } from "~/routes/($locale)+/resources+/context/BasketContext";

import { useStoreLocation } from "./resources+/store-location";

export const shouldRevalidate: ShouldRevalidateFunction = ({
  formMethod,
  formAction,
  currentUrl,
}) => {
  if (formMethod === "POST" && formAction?.includes("set-store")) return true;
  if (formMethod === "POST" && formAction?.includes("setClickAndCollect"))
    return true;
  if (formMethod === "POST" && formAction?.includes("/basket")) return true;
  if (formMethod === "POST" && formAction?.includes("/login")) return true;
  if (formMethod === "POST" && formAction?.includes("/register")) return true;
  if (formMethod === "POST" && formAction?.includes("/removeVehicle"))
    return true;
  if (formMethod === "POST" && formAction?.includes("/changeAccountDetails"))
    return true;
  if (
    formMethod === "POST" &&
    formAction?.includes("/resources/store-location")
  )
    return true;
  if (
    formMethod === "POST" &&
    formAction?.includes("/resources/vehicle/vehicle-search")
  )
    return true;
  if (
    formMethod === "POST" &&
    formAction?.includes("/resources/vehicle/vehicle-shop-by-vehicle")
  )
    return true;
  if (currentUrl.pathname.includes("/logout")) {
    return true;
  }

  return false;
};

export const loader = async ({ request, context }: LoaderArgs) => {
  const { time } = getTimingContext();
  const { env } = getSessionServerContext();
  const url = new URL(request.url);
  const preview = !!url.searchParams.get("preview");
  const client = getClient(preview);
  const [swrContent] = SWR.stable(
    "global-static-content",
    /* tags */
    ["contentful"],
    /* cache control */
    "s-maxage=30, stale-while-revalidate=3600",
  );
  const [swrContentCategories] = SWR.stable(
    "categories-content",
    /* tags */
    ["categories"],
    /* cache control */
    "s-maxage=3600, stale-while-revalidate=86400",
  );
  const { shop, site, basename, session } = context;
  const user = await time(optionalAuthenticatedUser(), {
    type: "auth",
    desc: "Get User",
  });

  const configurationTemplate = await time(
    swrContent(
      /* data */
      async () => {
        return await client.getEntries<TypeConfigurationTemplateSkeleton>({
          content_type: LAYOUT_CONFIG_TYPE,
          "fields.name": LAYOUT_CONFIG_NAME,
          limit: 1,
          include: 5,
        });
      },
      /* cache deps */
      [preview],
      /* cache tags */
      data => [data.items[0].sys.id],
    ).then(data => data.items[0].fields),
    {
      type: "contentful",
      desc: "Configuration Template",
    },
  );

  const [categories] = await time(
    deferDataRequest(swrContentCategories(() => shop.getCategories(), [])),
    {
      type: "layout-categories",
      desc: "[layout] Get Categories",
    },
  );

  const myStoreInfo = session.get("myStoreInfo");
  const loginFlashMessage = session.get("loginFlashMessage");
  const vehicle = session.get("vehicle");
  const vehicleKey = session.get("vehicleKey");
  const vehiclePartsCount = session.get("vehiclePartsCount");
  const isVehicleSuitabilityToggled = session.get(
    "isVehicleSuitabilityToggled",
  );

  // commit session to save the flash message
  if (loginFlashMessage) {
    await commitSession();
  }
  return defer(
    {
      configurationTemplate,
      loginFlashMessage,
      categories,
      preview,
      myStoreInfo,
      user,
      googleMapsApiKey: _env(env.GOOGLE_MAPS_API_KEY, "GOOGLE_MAPS_API_KEY"),
      categoryDepth: CATALOG_CONFIG.categoryHirarchyLevel,
      vehicle,
      vehicleKey,
      vehiclePartsCount,
      isVehicleSuitabilityToggled,
      isLoyaltyMember: context.session.get("isLoyaltyMember"),
      gtmContainerId: site.getGtmContainerId(env),
      basename,
    },
    {
      headers: {},
    },
  );
};

export const useRootLayoutData = () => {
  const data = useRouteLoaderData<typeof loader>("routes/($locale)+/_layout");
  invariant(data, "useRootLayoutData must be used within root layout");
  return data;
};

export default function _AppLayoutBapcor() {
  const boundryRef = useRef<HTMLDivElement>(null);
  const ContentHeroSlot = useSlot("contentRootHeroSlot");
  const ContentBotomSlot = useSlot("contentRootBottomSlot");
  const {
    configurationTemplate,
    preview,
    categories,
    categoryDepth,
    myStoreInfo,
    basename,
  } = useRootLayoutData();
  useStoreLocation(myStoreInfo);

  const liveConfigurationTemplate = useContentfulLiveUpdates(
    configurationTemplate,
  );
  const configurationTemplateData =
    preview && liveConfigurationTemplate
      ? liveConfigurationTemplate
      : configurationTemplate;

  invariant(configurationTemplateData, "No configuration template data");

  /*  NB:
      isWithStickyBoundry
      This is a flag to determine if the content should be rendered in the sticky boundry or not
      The case here is that the content should be rendered in the sticky boundry element
      Usecase: 
      - PLP have their sticky filters that need to push the sticked content up
    */
  const isWithStickyBoundry = useRouteHandle()?.withStickyBoundry === true;
  /* content will be rendered in different place depending on the sticky bountry */
  const content = (
    <main
      id="blurrable-content"
      className={`relative ${!isWithStickyBoundry ? "mt-nav" : ""}`}
    >
      {/* HERO Banner */}
      <Outlet />
      {/* Footer --- */}
      <ContentBotomSlot />
    </main>
  );

  return (
    <EmarsysContext>
      <PrefetchPageAnchors
        options={{
          preventScrollRestoration: url => {
            return url.toLocaleLowerCase().includes("drawer=");
          },
        }}
        basename={basename}
      >
        <MediaScreenProvider>
          <LoginFlash />
          <BasketProvider>
            {/* Header */}
            <div className="relative">
              <CategoryProvider
                categories={categories}
                categoryDepth={categoryDepth}
              >
                <Meganav
                  boundryRef={boundryRef}
                  desktopNavBarContent={
                    configurationTemplateData.desktopNavbarLinks
                  }
                  navigationSectionContent={
                    configurationTemplateData.navigationSections
                  }
                  uspBannerContent={configurationTemplateData.headerBannerUsp}
                  alertBannerContent={configurationTemplateData.alertBanner}
                />
              </CategoryProvider>
              <ContentHeroSlot />
              {/* slot hero slot  */}
              {/* Banner / content */}
              {/* 
            isWithStickyBoundry
            This is a flag to determine if the content should be rendered in the sticky boundry or not
            The case here is that the content should be rendered in the sticky boundry element
            Usecase: 
            - PLP have their sticky filters that need to push the sticked content up
          */}
              {isWithStickyBoundry ? null : content}
            </div>
            {/* Body */}
            {/* 
            isWithStickyBoundry
            This is a flag to determine if the content should be rendered in the sticky boundry or not
            The case here is that the content should be rendered outside the sticky boundry element 

            Usecase: 
            - PLP have their sticky filters that need to push the sticked content up
          */}
            {isWithStickyBoundry ? content : null}
            {/* Footer */}
            {configurationTemplateData.footer && (
              <Footer content={configurationTemplateData.footer} />
            )}

            {/* Floating Cookie Banner */}
            {configurationTemplateData.cookieConsent && (
              <ConsentBanner
                content={configurationTemplateData.cookieConsent}
              />
            )}

            <RootToaster />

            {/* <AddedToBasketFlyout /> */}
            <SendEmarsysEvents />
          </BasketProvider>
        </MediaScreenProvider>
      </PrefetchPageAnchors>
    </EmarsysContext>
  );
}

export const RootToaster = memo(() => {
  const { hash } = useLocation();
  // using hash in order to detect if mini-cart is opened. If it is - we are changing the position of the toaster
  return (
    <Toaster
      containerClassName={`${
        hash.includes("drawer=mini-cart")
          ? "!top-[82px]"
          : "!top-[140px] md:!top-[80px] md:!left-10 md:!right-10 lg:!top-[140px]"
      } `}
    />
  );
});
RootToaster.displayName = "RootToaster";

const useSlot = (contentKey: keyof AppHandle): (() => JSX.Element | null) => {
  const matches = useMatches();
  const match = useMemo(
    () => matches.reverse().find(match => (match as any).handle?.[contentKey]),
    [matches, contentKey],
  );
  return useCallback(() => {
    if (!match) return null;
    return (match as any).handle?.[contentKey]?.(match.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match?.pathname, matches]);
};

export const useRouteHandle = (): AppHandle | undefined => {
  const matches = useMatches();
  return useMemo(
    () =>
      (matches as any).reverse().find((match: { handle: any }) => match.handle)
        ?.handle,
    [matches],
  );
};

type StateCode = keyof typeof AU_STATE_CODE_NAMES_MAP;

export const getFullStateName = (stateCode: string | undefined) => {
  if (stateCode && stateCode in AU_STATE_CODE_NAMES_MAP) {
    return AU_STATE_CODE_NAMES_MAP[stateCode as StateCode];
  }
  return "Unknown State";
};

export const getStateCodeByName = (
  stateName: string | undefined,
): StateCode | undefined => {
  return (
    (Object.entries(AU_STATE_CODE_NAMES_MAP).find(
      ([, name]) => name === stateName,
    )?.[0] as StateCode) || undefined
  );
};
