import { P } from "~/components/ui/text";

type ProductListFilterNoResultsProps = {
  displayNoResults: boolean;
};
const ProductListFilterNoResults = ({
  displayNoResults,
}: ProductListFilterNoResultsProps) => {
  if (!displayNoResults) {
    return null;
  }

  return <P className="pl-2">No results found.</P>;
};

export default ProductListFilterNoResults;
