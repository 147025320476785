import { tv } from "tailwind-variants";

import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { useURL } from "~/contexts";
import { cn } from "~/lib/ui";

import type { CTAContent } from "../types";
import { ContentfulImage } from "./contentful-image";

const ICON_POSITION = {
  "Append Text": "flex-row",
  "Prepend Text": "flex-row-reverse",
};

const linkVariants = tv({
  base: "flex w-fit whitespace-nowrap text-sm lg:text-base font-extrabold px-4 lg:px-6 border-2 h-[44px] lg:h-[52px] rounded-md items-center gap-2 transition-colors duration-200 hover:no-underline ",
  variants: {
    type: {
      link: "border-0 p-0 underline",
      primary:
        "bg-brand-primary-black text-white border-brand-primary-black hover:border-dark-gray hover:bg-dark-gray disabled:bg-button-disabled",
      secondary:
        "bg-white text-brand-primary-black border-brand-primary-black hover:border-brand-primary-black hover:text-brand-primary-black",
      tertiary:
        "bg-button-primary text-black border-button-primary hover:border-button-primary-active hover:bg-button-primary-active",
    },
  },
  defaultVariants: {
    type: "primary",
  },
});

const VARIANT_TYPE_MAP = {
  Link: "link",
  "Primary Button": "primary",
  "Secondary Button": "secondary",
  "Tertiary Button": "tertiary",
} as const;

export const ContentfulCTA = ({
  content,
  className = "",
  onClick,
}: {
  content: CTAContent;
  className?: string;
  onClick?: () => void;
}) => {
  const url = useURL();
  const fields = content.fields;
  const link = fields.ctaLink;
  const inspectorProps = useContentfulInspectorMode();

  const variantType =
    (fields.type && VARIANT_TYPE_MAP[fields.type]) || "primary"; // default to primary if type is undefined

  return (
    <a
      className={cn(
        linkVariants({ type: variantType }),
        ICON_POSITION[fields.iconPosition || "Append Text"],
        className,
      )}
      href={url(link, { includeBasename: true })}
      title={content.fields.title}
      onClick={onClick}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
    >
      {fields.ctaText}
      {fields.icon?.fields.file?.url && (
        <ContentfulImage
          imageUrl={fields.icon?.fields.file?.url}
          alt={fields.icon?.fields.file?.url || ""}
          className="h-5 w-5"
          icon
        />
      )}
    </a>
  );
};
