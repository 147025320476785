import type { FacetValue } from "~/commerce-sap/.server/api/generated/__generated_apis";

/**
 * Helper function to convert a refinements object to a search string
 * @usage
 * ```ts
 *   const selectedRefinements = getRefinementsObjectFromString(
 *        searchParams.get("refine") ?? "",
 *    );
 * ```
 */
export const getRefinementsObjectFromString = (refine: string) =>
  refine.split(":").reduce(
    (acc, curr) => {
      const [key, value] = curr.split("=");
      if (key && value) {
        acc[key] = value.split("|");
      }
      return acc;
    },
    {} as Record<string, string[]>,
  );

/**
 * Helper function to convert a refinements object to a search string
 * @usage
 * ```ts
 *   const selectedRefinements = getRefinementsObjectFromString(
 *        searchParams.get("refine") ?? "",
 *    );
 *   // set the params
 *   const searchParams.set("refine", objectToSearchString($refinements))
 * ```
 */
export const objectToSearchString = (obj: Record<string, string[]>) =>
  Object.entries(obj)
    .map(([key, value]) => `${key}=${value.join("|")}`)
    .join(":");

export const parseRefinementsToQuery = (refine: string) => {
  const $refine = decodeURIComponent(refine)?.split(":") ?? [];
  const refinements: string[] = [];

  $refine.forEach(refinement => {
    const [key, value] = refinement.split("=");
    if (key && value) {
      value.split("|").forEach(v => {
        refinements.push([key, v].join(":"));
      });
    }
  });

  return refinements.join(":");
};

export const findRefinementId = (refinementValues: FacetValue[]) => {
  const firstValue = refinementValues?.[0]?.name;
  const filteredValues = refinementValues?.filter(
    value => value?.query?.query?.value?.indexOf(`:${firstValue}`) !== -1,
  );
  const [refinementId] =
    filteredValues?.[0]?.query?.query?.value?.split(":")?.slice(-2) ?? [];

  return refinementId;
};

export const updateRefinement = (
  refineQueryValue: string,
  id: string,
  newValue: string[],
) => {
  const { [id]: _drop, ...$refinements } =
    getRefinementsObjectFromString(refineQueryValue);
  $refinements[id] = newValue;
  return objectToSearchString($refinements);
};

export const clearRefinements = (refineQueryValue: string) => {
  const { ...$refinements } = getRefinementsObjectFromString(refineQueryValue);
  for (const i in $refinements) {
    $refinements[i] = [];
  }
  return objectToSearchString($refinements);
};
