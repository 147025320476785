import { useContentfulInspectorMode } from "@contentful/live-preview/react";

import { cn } from "~/lib/ui";

import type { IFrameContent } from "../types";
import { getMaxWidthClass } from "../util";

export const ContentfulIFrame = ({
  content,
  className,
}: {
  content: IFrameContent;
  className?: string;
}) => {
  const inspectorProps = useContentfulInspectorMode();
  const iframeHeight = content.fields.height
    ? `${content.fields.height}px`
    : "100vh"; // Default iFrame height of 100vh

  const maxWidthClass = getMaxWidthClass(content.fields.maxWidth);

  return (
    <iframe
      title={content.fields.name}
      src={content.fields.url}
      style={{ height: iframeHeight }}
      className={cn("no-scroll mx-auto w-full", maxWidthClass, className)}
      {...inspectorProps({ entryId: content.sys.id, fieldId: "name" })}
    />
  );
};
