import ArrowDownIcon from "~/components/ui/arrow-down-icon";
import { P } from "~/components/ui/text";

type ProductListFilterHeaderProps = {
  filterName: string;
  selectedValues: string[];
  filterAttributeId: string;
  isExpanded: boolean;

  displaySelectedValues?: boolean;
  toggleExpand: () => void;
  clearSelection?: (filterLabel: string) => void;
};

const ProductListFilterHeader = ({
  filterName,
  selectedValues,
  filterAttributeId,
  isExpanded,
  displaySelectedValues = true,
  clearSelection,
  toggleExpand,
}: ProductListFilterHeaderProps) => {
  return (
    <div
      className="flex cursor-pointer flex-col items-center justify-between justify-items-center align-middle"
      onClick={toggleExpand}
      onKeyDown={() => null}
      role="button"
      tabIndex={0}
    >
      <div className="flex w-full items-center justify-between justify-items-center align-middle">
        <P className="flex items-center text-base font-bold">{filterName}</P>
        <div className="flex flex-row items-center text-center">
          {selectedValues.length > 0 && (
            <div
              role="button"
              tabIndex={0}
              onKeyDown={() => null}
              onClick={e => {
                clearSelection && clearSelection(filterAttributeId);
                e.stopPropagation();
                e.preventDefault();
              }}
            >
              <P className="pl-3 text-xs font-bold underline">Clear All</P>
            </div>
          )}
          <ArrowDownIcon className="sm:h-5 sm:w-5 md:h-8 md:w-8" />
        </div>
        {selectedValues.length > 0 && !isExpanded && displaySelectedValues && (
          <div className="flex w-full justify-items-start pt-[10px]">
            <P className="text-xs font-bold text-neutral-3">
              {selectedValues.join(", ")}
            </P>
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductListFilterHeader;
