import { useState } from "react";
import { namedAction } from "remix-utils/named-action";
import { z } from "zod";

import {
  useActionData,
  useFetcher,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "@remix-run/react";
import { json } from "@remix-run/server-runtime";

import { handleLoginAndSync } from "./utils.server";
import {
  commitSession,
  isAuthenticatedUser,
  resetSessionWithOldData,
} from "@commerce/.server/sessions.server";
import { validationError } from "~/components/forms/validationErrorResponse.server";
import { assertIsPost, safeRedirect } from "~/lib/utils-server/http.server";
import { getPageMeta } from "~/seo/servers/seo.server";

import { withZod } from "@remix-validated-form/with-zod";

import { isValidationError } from "~/components/forms/validationErrorResponse";
import { useURL } from "~/contexts";
import { ERR_MESSAGES } from "~/lib/constants";
import type { ActionArgs } from "~/lib/loadargs.types";
import { useOnActionCompleted } from "~/lib/remix/fetcher";
import { emailValidator } from "~/lib/schemas/validations";
import { cn } from "~/lib/ui";
import EmailCheckForm from "~/routes/($locale)+/_auth+/components/email-check-form";
import LoginForm from "~/routes/($locale)+/_auth+/components/login-form";

import { ACCOUNT_LANDING_PAGE_URL } from "../account+/consts";
import ResetPasswordFlash from "./components/reset-password-flash";

export const loginSchema = z.object({
  password: z
    .string()
    .min(1, {
      message: "Password field cannot be empty. Please enter your password.",
    })
    .max(66),
  email: emailValidator,
});
export const checkEmailSchema = z.object({
  email: emailValidator,
});
export const resetPasswordSchema = z.object({
  email: emailValidator,
});

export const checkEmailValidator = withZod(checkEmailSchema);
export const resetPasswordValidator = withZod(resetPasswordSchema);
export const loginSchemaValidator = withZod(loginSchema);

export const loader = async ({ context }: LoaderArgs) => {
  const isLoggedin = isAuthenticatedUser();
  if (isLoggedin) {
    throw safeRedirect(ACCOUNT_LANDING_PAGE_URL);
  }

  const successPaswordResetMessage = context.session.get(
    "successPaswordResetMessage",
  );

  const seo = getPageMeta({
    type: "login",
    options: {
      title: "Login | Autobarn",
      description: "Autobarn login page",
    },
    robots: {
      noFollow: false,
      noIndex: true,
    },
  });

  return json({
    seo,
    successPaswordResetMessage,
  });
};

export const action = async ({ request, context }: ActionArgs) => {
  return namedAction(request, {
    async checkLoginId() {
      assertIsPost(request);
      const validation = await checkEmailValidator.validate(
        await request.formData(),
      );
      const { auth } = context;
      if (validation.error)
        return validationError(validation.error, validation.submittedData);

      try {
        const isLoginIdAvailable = await auth.checkLoginId(
          validation.data.email,
        );

        // if (isLoginIdAvailable) {
        //   throw safeRedirect(`/register`);
        // }

        return json({
          customerEmail: validation.data.email,
          isLoginIdAvailable,
        });
      } catch (e) {
        if (e instanceof Error) {
          console.log(e.stack || e);

          return validationError(e.message, validation.submittedData);
        }
        throw e;
      }
    },
    async loginUser() {
      assertIsPost(request);

      const formData = await request.formData();
      const queryParams = new URL(request.url).searchParams;

      const validation = await loginSchemaValidator.validate(formData);
      const { session } = context;
      if (validation.error)
        return validationError(validation.error, validation.submittedData);

      try {
        const user = await handleLoginAndSync(
          context,
          validation.data.email,
          validation.data.password,
        );
        session.set("userProfile", user);
        session.flash("loginFlashMessage", {
          title: `Welcome back, ${user.firstName}`,
          message: "Ready to start shopping? Enjoy the ride!",
        });
        const redirectUrl = context.session.get("afterLoginRedirectUrl");
        await commitSession();
        if (redirectUrl) {
          return safeRedirect(redirectUrl, {
            headers: {
              "Set-Cookie": await resetSessionWithOldData(),
            },
          });
        }

        /* if we are logging from a drawer , just close the drawer */
        if (queryParams.get("noRedirect") === "true") {
          return json(
            { success: true, uid: context.session.get("uid") },
            {
              headers: {
                "Set-Cookie": await resetSessionWithOldData(),
              },
            },
          );
        }
        return safeRedirect("/", {
          headers: {
            "Set-Cookie": await resetSessionWithOldData(),
          },
        });
      } catch (e) {
        if (e instanceof Error) {
          console.log(e.stack || e);
          const code = e.message.split(": ")[0];

          if (code.length > 0 && ERR_MESSAGES[code]) {
            return validationError(
              ERR_MESSAGES[code],
              validation.submittedData,
            );
          }

          return validationError(e.message, validation.submittedData);
        }
        throw e;
      }
    },
  });
};

type LoginStep = "email-check" | "login";

const Login = () => {
  const transition = useNavigation();
  const fetcher = useFetcher<typeof action>({ key: "login" });
  const fetcherError = useFetcher<typeof action>();
  const actionData = useActionData<typeof action>() || fetcher.data;
  const url = useURL();
  const navigate = useNavigate();
  const data = useLoaderData<typeof loader>();

  const [loginStep, setLoginStep] = useState<LoginStep>("email-check");

  useOnActionCompleted<{ customerEmail?: string; isLoginIdAvailable: boolean }>(
    "checkLoginId",
    data => {
      if (!data?.isLoginIdAvailable) {
        setLoginStep("login");
      }
      if (data?.isLoginIdAvailable) {
        navigate(url("/register"), {
          state: { customerEmail: data.customerEmail },
        });
      }
    },
  );

  const isLoading = transition.state !== "idle" || fetcher.state !== "idle";

  const customerEmail = isValidationError(actionData)
    ? actionData.repopulateFields.email
    : actionData && "customerEmail" in actionData
      ? actionData.customerEmail
      : "";
  return (
    <div
      className={cn(
        "flex h-full justify-center px-6 py-10",
        "sm:px-6 sm:pt-[134px] md:px-8 md:pt-[80px] lg:px-10 lg:pt-[134px] xl:px-[95px]",
      )}
    >
      <ResetPasswordFlash message={data.successPaswordResetMessage} />
      <div
        className={cn("w-full flex-col space-y-8 md:flex", "flex-row gap-6")}
      >
        {loginStep === "login" && (
          <LoginForm
            handleBackButton={() => setLoginStep("email-check")}
            action={"/login/?action=loginUser"}
            fetcher={fetcher}
            actionData={actionData}
            isLoading={isLoading}
            customerEmail={customerEmail}
            isFlyoutMode={false}
            onForgotPasswordClick={() => navigate(url("/reset-password"))}
          />
        )}
        {loginStep === "email-check" && (
          <EmailCheckForm
            fetcher={fetcher}
            fetcherError={fetcherError}
            actionData={actionData}
            isLoading={isLoading}
            customerEmail={customerEmail}
            action={"/login?action=checkLoginId"}
            isFlyoutMode={false}
          />
        )}
      </div>
    </div>
  );
};

export default Login;
