import { P } from "~/components/ui/text";

type ProductListFilterFooterProps = {
  page: number;
  totalPages: number;
  setPage: (page: number) => void;
};
const ProductListFilterFooter = ({
  page,
  totalPages,
  setPage,
}: ProductListFilterFooterProps) => {
  return (
    <>
      {page < totalPages && (
        <div
          role="button"
          onKeyDown={() => null}
          tabIndex={0}
          onClick={() => setPage(page + 1)}
        >
          <P className="pl-[32px] pt-[16px] font-semibold">+ More</P>
        </div>
      )}
    </>
  );
};

export default ProductListFilterFooter;
