import { memo } from "react";

import { Link } from "@remix-run/react";

import { useAlgoliaInsights } from "~/algolia/algolia-insights";
import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import type { BapcorFitment } from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useProductListFiltrationContext } from "~/commerce-sap/routes/($locale)+/_product-list+/components/filtration/product-list-filtration-context";
import ProductTileImage from "~/commerce-sap/routes/($locale)+/_product-list+/components/product-tile/product-tile-image";
import useProductPrimaryImage from "~/commerce-sap/routes/($locale)+/_product-list+/hooks/use-product-primary-image";
import { IsGiftCard } from "~/commerce-sap/shop/utils/giftcard";
import {
  ADD_TO_CART_EVENT,
  REMOVE_FROM_CART_EVENT,
  transformAlgoliaProductForGTM,
  useGTMTracker,
} from "~/google-tagmanager";
import { cn } from "~/lib/ui";

import { useProductUrl } from "../../hooks/product-url";
import GiftcardShowCTA from "./gift-card-show-CTA";
import ProductTileCartCTA from "./product-tile-cart-CTA";
import { useProductTileData } from "./use-product-tile-data";

type ProductTileProps = {
  product: AlgoliaSearchResultHit;
  fitmentData:
    | {
        [key: string]: BapcorFitment[] | undefined;
      }
    | Promise<{
        [key: string]: BapcorFitment[] | undefined;
      }>;
  showSuitability: boolean;
  list_id: string;
  list_name: string;
  index: number;
  isLoyalityMember?: boolean;
  className?: string;
  isCarousel?: boolean;
  queryID?: string;
  indexUsed?: string;
};

const ProductTile = ({
  product,
  fitmentData,
  showSuitability,
  list_id,
  list_name,
  index = 0,
  isLoyalityMember,
  className,
  isCarousel = false,
  queryID,
  indexUsed,
}: ProductTileProps) => {
  const productURL = useProductUrl(product);
  const linkTo = `${productURL}?queryID=${queryID ?? ""}&indexUsed=${
    indexUsed ?? ""
  }`;
  const { getHystoryState } = useProductTileData(product);
  const { primaryImage } = useProductPrimaryImage({
    images: product.images ?? [],
  });
  const { selectedFilters } = useProductListFiltrationContext();
  const { selectItemEvent, cartEventsEvent } = useGTMTracker();
  const {
    clickedObjectIDs,
    clickedObjectIDsAfterSearch,
    convertedFilters,
    convertedObjectIDsAfterSearch,
    convertedObjectIds,
  } = useAlgoliaInsights();
  const isGiftCard = IsGiftCard(product);
  // make product code as upperCase because we always receive the product code as upperCase in the basket.
  product.code = product.code.toUpperCase();

  const addToCartEvent = () => {
    cartEventsEvent(
      ADD_TO_CART_EVENT,
      transformAlgoliaProductForGTM([product], index, isLoyalityMember),
    );

    if (queryID) {
      clickedObjectIDsAfterSearch({
        queryId: queryID,
        objectIds: product.objectID,
        positions: index + 1,
        index: indexUsed,
      });

      convertedObjectIDsAfterSearch({
        queryId: queryID,
        objectIds: product.objectID,
        index: indexUsed,
      });
    } else {
      clickedObjectIDs({
        objectIds: product.objectID,
        index: indexUsed,
      });

      convertedObjectIds({
        objectIds: product.objectID,
        index: indexUsed,
      });
    }

    if (Object.keys(selectedFilters).length > 0) {
      convertedFilters({
        filters: selectedFilters,
        index: indexUsed,
      });
    }
  };
  const removeFromCartEvent = () => {
    cartEventsEvent(
      REMOVE_FROM_CART_EVENT,
      transformAlgoliaProductForGTM([product], index, isLoyalityMember),
    );
  };

  return (
    <div
      className={cn(
        "flex w-full flex-col no-underline hover:no-underline sm:py-5 ",

        className,
        isCarousel
          ? "carousel-product-tile"
          : "listing-product-tile md:w-1/3 md:p-1.5 lg:w-1/4  lg:p-3",
      )}
      key={product?.code}
      data-insights-object-id={product.objectID}
      data-insights-position={index + 1}
      data-insights-query-id={queryID}
    >
      <div
        className={cn(
          "lg:p flex h-full flex-col px-[9px] pb-4 pt-4",
          isCarousel ? "border-light-gray  rounded-lg border" : "shadow-tile",
        )}
      >
        <div className="flex flex-grow flex-col">
          <div
            className={cn(
              "flex w-full gap-4 md:flex-col",
              isGiftCard ? "flex-col" : "flex-row",
              isCarousel ? "flex-col" : "flex-row",
            )}
          >
            <ProductTileImage
              product={product}
              linkTo={linkTo}
              list_id={list_id}
              list_name={list_name}
              index={index}
              isCarousel={isCarousel}
              isGiftCard={isGiftCard}
              queryID={queryID}
              indexUsed={indexUsed}
            />
            <div className={isGiftCard ? "h-16" : "h-20"}>
              <div className="pb-2 text-base font-bold">
                <Link
                  state={getHystoryState(primaryImage, {
                    list_id,
                    list_name,
                    index,
                  })}
                  to={linkTo}
                  onClick={() => {
                    selectItemEvent(
                      {
                        code: product.code,
                        name: product.name,
                        brand: product.brand,
                        price: product.price,
                        partNumber: product.partNumber,
                        index,
                        list_id,
                        list_name,
                      },
                      index,
                      list_id,
                      list_name,
                    );

                    clickedObjectIDsAfterSearch({
                      queryId: queryID,
                      objectIds: product.objectID,
                      positions: index + 1,
                      index: indexUsed,
                    });
                  }}
                >
                  <span className="line-clamp-2">{product.name}</span>
                </Link>
              </div>
              <Link
                state={getHystoryState(primaryImage, {
                  list_id,
                  list_name,
                  index,
                })}
                to={`${productURL}#reviews`}
                className="no-underline hover:no-underline"
                preventScrollReset
              >
                <div
                  className="min-h-5 pb-2"
                  data-bv-show="inline_rating"
                  data-bv-product-id={product.code}
                ></div>
              </Link>
            </div>
          </div>
          {isGiftCard ? (
            <GiftcardShowCTA
              product={product}
              queryID={queryID}
              indexUsed={indexUsed}
            />
          ) : (
            <ProductTileCartCTA
              product={product}
              fitmentData={fitmentData}
              showSuitability={showSuitability}
              isLoyalityMember={isLoyalityMember}
              addToCartEvent={addToCartEvent}
              removeFromCartEvent={removeFromCartEvent}
              queryID={queryID}
              indexUsed={indexUsed}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(ProductTile);
