import { useState } from "react";

type UseProductListFiltrationReturnType = {
  filtersOpen?: boolean;
  setFiltersOpen: (value: boolean) => void;
  sortingOpen?: boolean;
  setSortingOpen: (value: boolean) => void;
  openFilterId?: string;
  setOpenFilterId: (value: string) => void;
  onFilterOpen: (filterId: string | undefined) => void;
  numberOfFiltersApplied: number;
};

type UseProductListFiltrationArguments = {
  selectedRefinements: Record<string, string[]>;
};

type UseProductListFiltration = (
  props: UseProductListFiltrationArguments,
) => UseProductListFiltrationReturnType;

const useProductListFiltration: UseProductListFiltration = ({
  selectedRefinements,
}) => {
  const [filtersOpen, setFiltersOpen] = useState<boolean>();
  const [sortingOpen, setSortingOpen] = useState<boolean>();

  const [openFilterId, setOpenFilterId] = useState<string | undefined>();

  const onFilterOpen = (filterId: string | undefined) => {
    setFiltersOpen(true);
    setOpenFilterId(filterId);
  };

  const numberOfFiltersApplied =
    Object.values(selectedRefinements).flat().length;

  return {
    filtersOpen,
    setFiltersOpen,
    sortingOpen,
    setSortingOpen,
    openFilterId,
    setOpenFilterId,
    onFilterOpen,
    numberOfFiltersApplied,
  };
};

export default useProductListFiltration;
