import { Link } from "@remix-run/react";

import type { AlgoliaSearchResultHit } from "~/algolia/algolia.types";
import useProductPrimaryImage from "~/commerce-sap/routes/($locale)+/_product-list+/hooks/use-product-primary-image";
import { useURL } from "~/contexts";

import { RRPrice } from "../price/price-utils";
import { useProductTileData } from "./use-product-tile-data";

type Props = {
  product: AlgoliaSearchResultHit;
  queryID?: string;
  indexUsed?: string;
};

const GiftcardShowCTA = ({ product, queryID, indexUsed }: Props) => {
  const url = useURL();
  const { getHystoryState } = useProductTileData(product);
  const { primaryImage } = useProductPrimaryImage({
    images: product.images ?? [],
  });

  const linkTo = `${url(`/${product.code}.html`)}?queryID=${
    queryID ?? ""
  }&indexUsed=${indexUsed ?? ""}`;

  return (
    <>
      <div className="flex pb-3">
        <RRPrice price={product.priceRangeMap?.minPrice} className="text-xl" />
        <span className="px-0.5 text-xl font-bold">-</span>
        <RRPrice price={product.priceRangeMap?.maxPrice} className="text-xl" />
      </div>
      <Link
        state={getHystoryState(primaryImage)}
        className="mt-auto flex min-h-11 w-full justify-center rounded-md bg-brand-primary-black px-4 py-3 text-base font-extrabold text-white hover:no-underline md:ml-auto"
        to={linkTo}
      >
        Shop Now
      </Link>
    </>
  );
};

export default GiftcardShowCTA;
