import { SearchIcon } from "~/components/ui/icons/search-icon";
import { Checkbox } from "~/components/ui/input";
import { Label } from "~/components/ui/label";
import { SearchInput } from "~/components/ui/search-input";
import { P } from "~/components/ui/text";

import type { ProductListFilterSelectComponent } from "../filter-types";
import useProductListFilterSelect from "../hooks/use-product-list-filter-select";
import ProductListFilterFooter from "./product-list-filter-footer";
import ProductListFilterHeader from "./product-list-filter-header";
import ProductListFilterNoResults from "./product-list-filter-no-results";

export enum ProductListFilterDialogFilterRenderEnum {
  Base = "base",
  Boolean = "boolean",
  StoreStock = "storeStock",
}

const BaseProductListFilterSelect: ProductListFilterSelectComponent = ({
  filterName,
  filterAttributeId,
  selectedValues = [],
  values = [],
  clearSelection,
  expanded,
}) => {
  const {
    onFilterSelect,
    toggleExpand,
    setPage,
    onFilter,
    isExpanded,
    contentHeight,
    contentRef,
    filterString,
    displayedValues,
    filteredValues,
    page,
    totalPages,
  } = useProductListFilterSelect({
    expanded,
    values,
    filterName,
  });

  return (
    <div key={filterName} className="w-full">
      <div className="h-fit w-full">
        <ProductListFilterHeader
          filterName={filterName}
          selectedValues={selectedValues}
          filterAttributeId={filterAttributeId}
          isExpanded={isExpanded}
          displaySelectedValues={false}
          toggleExpand={toggleExpand}
          clearSelection={clearSelection}
        />
        <div
          className="overflow-hidden pt-[22px] transition-all duration-500 ease-in-out"
          style={{
            height: isExpanded ? `${contentHeight}px` : "0px",
          }}
        >
          <div ref={contentRef} className="">
            {values.length > 15 && (
              <SearchInput
                value={filterString}
                onChange={onFilter}
                className="pr-10 text-sm font-semibold sm:placeholder-neutral-1 lg:placeholder-neutral-1"
                icon={
                  <SearchIcon
                    className={
                      "absolute right-3 top-1/2 h-6 w-6 -translate-y-1/2 transform text-muted-foreground"
                    }
                  />
                }
              />
            )}
            <div
              className={`${
                selectedValues.length > 0 && isExpanded ? "pt-3" : ""
              }`}
            >
              <P className="text-xs font-bold text-neutral-3">
                {selectedValues.join(", ")}
              </P>
            </div>
            <div className="pb-3 pt-3">
              {displayedValues.map(value => {
                const isSelected = selectedValues.includes(
                  decodeURIComponent(value.value),
                );
                return (
                  <div
                    key={value.value}
                    className="grid cursor-pointer grid-cols-[32px_auto] items-center gap-[8px] pt-2"
                  >
                    <Checkbox
                      onChange={() => {
                        onFilterSelect(filterAttributeId, value);
                      }}
                      checked={isSelected}
                      iconSize={25}
                      id={value.label}
                    />
                    <Label
                      className="flex-grow cursor-pointer text-base"
                      htmlFor={value.label}
                    >
                      {value.label}{" "}
                      {filterAttributeId != "rrpPrice"
                        ? `(${value.count})`
                        : ""}
                    </Label>
                  </div>
                );
              })}
              <ProductListFilterNoResults
                displayNoResults={filteredValues.length === 0}
              />
              <ProductListFilterFooter
                page={page}
                totalPages={totalPages}
                setPage={setPage}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-[100%] bg-neutral-7"></div>
    </div>
  );
};

export default BaseProductListFilterSelect;
