import type { IconProps } from "../types";

const ArrowDownIcon = ({
  size = 32,
  fill = "#121212",
  ...props
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 20 21"
      fill={fill}
      {...props}
    >
      <path
        d="M10.0013 12.9788C9.89019 12.9788 9.78602 12.9615 9.6888 12.9268C9.59158 12.892 9.5013 12.833 9.41797 12.7497L5.58464 8.91634C5.43186 8.76356 5.35547 8.56912 5.35547 8.33301C5.35547 8.0969 5.43186 7.90245 5.58464 7.74967C5.73741 7.5969 5.93186 7.52051 6.16797 7.52051C6.40408 7.52051 6.59852 7.5969 6.7513 7.74967L10.0013 10.9997L13.2513 7.74967C13.4041 7.5969 13.5985 7.52051 13.8346 7.52051C14.0707 7.52051 14.2652 7.5969 14.418 7.74967C14.5707 7.90245 14.6471 8.0969 14.6471 8.33301C14.6471 8.56912 14.5707 8.76356 14.418 8.91634L10.5846 12.7497C10.5013 12.833 10.411 12.892 10.3138 12.9268C10.2166 12.9615 10.1124 12.9788 10.0013 12.9788Z"
        fill={fill}
      />
    </svg>
  );
};

export default ArrowDownIcon;
