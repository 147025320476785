import type { ChangeEventHandler } from "react";
import { useCallback, useEffect, useRef, useState } from "react";

import { useProductListFiltrationContext } from "~/commerce-sap/routes/($locale)+/_product-list+/components/filtration/product-list-filtration-context";

import type { ProductListFilterValue } from "../filter-types";

type UseProductListFilterSelectProps = {
  expanded: boolean;
  values: ProductListFilterValue[];
  filterName?: string;
};

type UseProductListFilterSelectResponse = {
  onFilterSelect: (filter: string, value: ProductListFilterValue) => void;
  toggleExpand: () => void;
  setPage: (page: number) => void;
  onFilter: ChangeEventHandler<HTMLInputElement>;
  isExpanded: boolean;
  contentHeight: number;
  contentRef: React.RefObject<HTMLDivElement>;
  filterString: string;
  displayedValues: ProductListFilterValue[];
  filteredValues: ProductListFilterValue[];
  page: number;
  totalPages: number;
};

type UseProductListFilterSelect = (
  props: UseProductListFilterSelectProps,
) => UseProductListFilterSelectResponse;

const useProductListFilterSelect: UseProductListFilterSelect = ({
  expanded,
  values,
  filterName,
}) => {
  const sortedValues = values.sort((a, b) => {
    if (filterName?.toLowerCase() === "brand") {
      return a.label.localeCompare(b.label);
    }
    if (a.count && b.count) {
      return b.count - a.count;
    }
    return 0;
  });
  const [isExpanded, setIsExpanded] = useState(expanded);
  const [filteredValues, setFilteredValues] = useState(sortedValues);
  const { onFilterSelect } = useProductListFiltrationContext();
  const [displayedValues, setDisplayedValues] = useState<
    ProductListFilterValue[]
  >([]);

  const [page, setPage] = useState(1);
  const [totalPages, setTotalPages] = useState(
    Math.round(sortedValues.length / 6),
  );
  const [filterString, setFilterString] = useState("");

  const contentRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    if (contentRef.current) {
      setContentHeight(contentRef.current.scrollHeight + 22);
    }
  }, [displayedValues]);

  useEffect(() => {
    setPage(1);
  }, [isExpanded]);

  useEffect(() => {
    setPage(1);
    if (!filterString) {
      setFilteredValues(sortedValues);
      setTotalPages(Math.round(sortedValues.length / 6));
      return;
    }

    const filtered = sortedValues.filter(value =>
      value.label.toLowerCase().includes(filterString.toLowerCase()),
    );
    setTotalPages(Math.round(filtered.length / 6));
    setFilteredValues(filtered);
  }, [filterString, sortedValues]);

  useEffect(() => {
    if (sortedValues.length < 6) {
      setDisplayedValues(filteredValues);
      return;
    }

    const end = (page - 1) * 6 + 6;
    setDisplayedValues(filteredValues.slice(0, end));
  }, [filteredValues, page, sortedValues.length]);

  const onFilter: ChangeEventHandler<HTMLInputElement> = useCallback(
    event => setFilterString(event.target.value),
    [],
  );

  const toggleExpand = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded]);

  return {
    onFilterSelect,
    toggleExpand,
    setPage,
    onFilter,
    isExpanded,
    contentHeight,
    contentRef,
    filterString,
    displayedValues,
    filteredValues,
    page,
    totalPages,
  };
};

export default useProductListFilterSelect;
