import { useMemo } from "react";

import { Checkbox } from "~/components/ui/input";
import { P } from "~/components/ui/text";
import { useRootLayoutData } from "~/routes/($locale)+/_layout";

import type { ProductListFilterSelectComponent } from "../filter-types";
import useProductListFilterSelect from "../hooks/use-product-list-filter-select";
import ProductListFilterHeader from "./product-list-filter-header";

export enum ProductListFilterDialogFilterRenderEnum {
  Base = "base",
  Boolean = "boolean",
  StoreStock = "storeStock",
}

const StoreStockProductListFilterSelect: ProductListFilterSelectComponent = ({
  filterName,
  filterAttributeId,
  selectedValues = [],
  values = [],
  clearSelection,
  expanded,
}) => {
  const {
    onFilterSelect,
    toggleExpand,
    isExpanded,
    contentHeight,
    contentRef,
  } = useProductListFilterSelect({
    expanded,
    values,
  });

  const { myStoreInfo } = useRootLayoutData();
  const selectedStoreKey = myStoreInfo?.store?.name;

  const storeValue = useMemo(
    () => values.find(value => value.value === selectedStoreKey),
    [values, selectedStoreKey],
  );

  const isSelected =
    storeValue && selectedValues.includes(decodeURIComponent(storeValue.value));

  if (!myStoreInfo?.store?.name || !storeValue) {
    return;
  }

  return (
    <div key={filterName} className="w-full">
      <div className="h-fit w-full">
        <ProductListFilterHeader
          filterName={filterName}
          selectedValues={selectedValues}
          filterAttributeId={filterAttributeId}
          isExpanded={isExpanded}
          displaySelectedValues={false}
          toggleExpand={toggleExpand}
          clearSelection={clearSelection}
        />
        <div
          className="overflow-hidden pt-[22px] transition-all duration-500 ease-in-out"
          style={{
            height: isExpanded ? `${contentHeight}px` : "0px",
          }}
        >
          <div ref={contentRef} className="">
            <div className="pb-3 pt-3">
              <>
                <div className="grid cursor-pointer grid-cols-[32px_auto] items-center gap-[8px] pt-2">
                  <Checkbox
                    onChange={() => {
                      // Trigger filter selection with the store value
                      onFilterSelect(filterAttributeId, storeValue);
                    }}
                    iconSize={25}
                    checked={isSelected} // Set the checkbox state based on whether the value is selected
                  />
                  <P className="flex-grow">
                    Available at current store {`(${storeValue.count})`}
                  </P>
                </div>
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="h-[1px] w-[100%] bg-neutral-7"></div>
    </div>
  );
};

export default StoreStockProductListFilterSelect;
