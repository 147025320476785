import { useCallback } from "react";

import type {
  Order,
  OrderEntry,
} from "~/commerce-sap/.server/api/generated/__generated_apis";
import { useGTMContext, useGTMTracker } from "~/google-tagmanager";
import { mapAttributeIdToValue } from "~/routes/($locale)+/product-list+/components/filtration/components/product-list-filter-select-factory";

export type AlgoliaInsightsType = {
  objectIds?: string[] | string;
  queryId?: string;
  positions?: number[] | number;
  filters?: { [key: string]: string[] };
  index?: string;
};

const parseFilters = (selectedFilters: AlgoliaInsightsType["filters"]) =>
  Object.entries(selectedFilters ?? {})
    .reduce<string[]>((acc, [key, values]) => {
      values.forEach(v => {
        const encodedKey = encodeURIComponent(key);
        const encodedValue = encodeURIComponent(mapAttributeIdToValue(key, v));
        acc.push(`${encodedKey}:${encodedValue}`);
      });
      return acc;
    }, [])
    .slice(0, 10);

export const useAlgoliaInsights = () => {
  const { sendAlgoliaInsightsToGTM } = useGTMTracker();
  const {
    gtmState: { algoliaIndexName },
  } = useGTMContext();

  //-------------Event to track viewedObjectIDs activity --------------------
  const viewedObjectIDs = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Viewed ObjectIDs",
        algoliaInsights: {
          objectIDs: data.objectIds,
          index: data.index,
        },
      };

      payload.algoliaInsights.objectIDs?.length &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track clickedObjectIDsAfterSearch activity --------------------
  const clickedObjectIDs = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Clicked ObjectIDs",
        algoliaInsights: {
          objectIDs: data.objectIds,
          index: data.index,
        },
      };

      payload.algoliaInsights.objectIDs?.length &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track clickedObjectIDsAfterSearch activity --------------------
  const clickedObjectIDsAfterSearch = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Clicked ObjectIDs After Search",
        algoliaInsights: {
          queryID: data.queryId,
          objectIDs: data.objectIds,
          positions: data.positions,
          index: data.index,
        },
      };

      payload.algoliaInsights.objectIDs?.length &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  const convertedObjectIds = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Converted ObjectIDs",
        algoliaInsights: {
          objectIDs: data.objectIds,
          index: data.index,
        },
      };

      payload.algoliaInsights.objectIDs?.length &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track convertedObjectIDsAfterSearch activity --------------------
  const convertedObjectIDsAfterSearch = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Converted ObjectIDs After Search",
        algoliaInsights: {
          queryID: data.queryId,
          objectIDs: data.objectIds,
          index: data.index,
        },
      };

      payload.algoliaInsights.objectIDs?.length &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track viewedFilters activity --------------------
  const viewedFilters = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Viewed Filters",
        algoliaInsights: {
          filters: parseFilters(data.filters),
          index: data.index,
        },
      };

      payload.algoliaInsights.filters.length > 0 &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track clickedFilters activity --------------------
  const clickedFilters = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Clicked Filters",
        algoliaInsights: {
          filters: parseFilters(data.filters),
          index: data.index,
        },
      };

      payload.algoliaInsights.filters.length > 0 &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  //-------------Event to track convertedFilters activity --------------------
  const convertedFilters = useCallback(
    (data: AlgoliaInsightsType) => {
      const payload = {
        event: "Converted Filters",
        algoliaInsights: {
          filters: parseFilters(data.filters),
          index: data.index,
        },
      };

      payload.algoliaInsights.filters.length > 0 &&
        sendAlgoliaInsightsToGTM(payload);
    },
    [sendAlgoliaInsightsToGTM],
  );

  const purchasedObjectIDsAfterSearch = useCallback(
    (order: Order) => {
      if (!order.entries?.length) return;

      const groupedEntries = order.entries.reduce<{
        withQueryId: Record<string, OrderEntry[]>;
        withoutQueryId: OrderEntry[];
      }>(
        (groups, entry) => {
          const [queryId, ...indexParts] =
            entry?.algoliaQueryId?.split("_") || [];
          const indexName = indexParts.join("_");

          queryId && indexName
            ? (groups.withQueryId[indexName] ||= []).push(entry)
            : groups.withoutQueryId.push(entry);

          return groups;
        },
        { withQueryId: {}, withoutQueryId: [] },
      );

      const getPrice = (entry: OrderEntry) =>
        ("giftCardData" in entry && entry.giftCardData
          ? entry?.totalPrice?.value
          : entry.product?.price?.value) ?? 0;

      const createPayload = (
        event: string,
        indexName: string,
        entries: OrderEntry[],
      ) => {
        const objectIDs = entries.map(entry => entry.product?.code);
        const objectData = entries.map(entry => {
          const data: {
            price: string;
            quantity: number;
            queryId?: string;
          } = {
            price: getPrice(entry).toFixed(2),
            quantity: entry.quantity ?? 1,
          };
          if (entry.algoliaQueryId) {
            data.queryId = entry.algoliaQueryId?.split("_")[0];
          }
          return data;
        });
        const value = entries
          .reduce(
            (acc, entry) => acc + getPrice(entry) * (entry.quantity ?? 1),
            0,
          )
          .toFixed(2);

        return {
          event,
          algoliaInsights: {
            objectIDs,
            objectData,
            value,
            currency: order.entries?.[0]?.product?.price?.currencyIso,
            index: indexName,
          },
        };
      };

      if (groupedEntries.withoutQueryId.length) {
        sendAlgoliaInsightsToGTM(
          createPayload(
            "Purchased ObjectIDs",
            algoliaIndexName,
            groupedEntries.withoutQueryId,
          ),
        );
      }

      Object.entries(groupedEntries.withQueryId).forEach(
        ([indexName, entries]: [string, OrderEntry[]]) => {
          if (entries.length) {
            sendAlgoliaInsightsToGTM(
              createPayload(
                "Purchased ObjectIDs After Search",
                indexName,
                entries,
              ),
            );
          }
        },
      );
    },
    [sendAlgoliaInsightsToGTM, algoliaIndexName],
  );

  return {
    viewedObjectIDs,
    clickedObjectIDs,
    clickedObjectIDsAfterSearch,
    convertedObjectIds,
    convertedObjectIDsAfterSearch,
    viewedFilters,
    clickedFilters,
    convertedFilters,
    purchasedObjectIDsAfterSearch,
  };
};
