import { createContext, useContext } from "react";

import { useSearchParams } from "@remix-run/react";

import useProductListFiltration from "~/commerce-sap/routes/($locale)+/_product-list+/components/filtration/hooks/use-product-list-filtration";
import { getRefinementsObjectFromString } from "~/commerce-sap/shop/utils/refinements";
import useAlgoliaFacetToFilter from "~/routes/($locale)+/product-list+/hooks/use-algolia-facet-to-filter";

import type { ProductListFilterValue } from "./product-list-filter-dialog/product-list-filter-select";

export type ProductListFiltrationContextType = {
  filtersOpen?: boolean;
  sortingOpen?: boolean;
  selectedFilters: { [key: string]: string[] };
  openFilterId?: string;
  selectedSorting: string;
  setFiltersOpen: (value: boolean) => void;
  setSortingOpen: (value: boolean) => void;
  setOpenFilterId: (value: string) => void;
  clearFilters: () => void;
  clearSelectionOfFilter: (labelName: string, forceReload?: boolean) => void;
  clearSingleValueOfFilter: (labelName: string, valueToRemove: string) => void;
  onFilterSelect: (filterId: string, value: ProductListFilterValue) => void;
};

const ProductListFilterContext = createContext({
  filtersOpen: false,
  sortingOptions: [],
  selectedSorting: "",
  sortingOpen: false,
  openFilterId: "",
  selectedFilters: {},
  setFiltersOpen: () => null,
  setSortingOpen: () => null,
  setOpenFilterId: () => null,
  onFilterSelect: () => null,
  clearFilters: () => null,
  clearSelectionOfFilter: () => null,
  clearSingleValueOfFilter: () => null,
  checkSelectedStore: () => null,
} as ProductListFiltrationContextType);

export const useProductListFiltrationContext = () => {
  return useContext(ProductListFilterContext);
};

type ProductListFiltrationContextProviderProps = {
  children: React.ReactNode;
};

export const ProductListFiltrationContext: React.FC<
  ProductListFiltrationContextProviderProps
> = ({ children }) => {
  const [searchParams] = useSearchParams();
  const selectedSorting = searchParams.get("sort") ?? "relevance";

  const selectedRefinements = getRefinementsObjectFromString(
    searchParams.get("query") ?? "",
  );

  const {
    filtersOpen,
    sortingOpen,
    openFilterId,
    setFiltersOpen,
    setSortingOpen,
    setOpenFilterId,
  } = useProductListFiltration({
    selectedRefinements,
  });

  const {
    clearFilters,
    applyFilters,
    selectedFilters,
    clearSelectionOfFilter,
    clearSingleValueOfFilter,
  } = useAlgoliaFacetToFilter();

  return (
    <ProductListFilterContext.Provider
      value={{
        filtersOpen,
        sortingOpen,
        selectedSorting,
        openFilterId,
        selectedFilters,
        setFiltersOpen,
        setSortingOpen,
        setOpenFilterId,
        clearFilters,
        clearSelectionOfFilter,
        clearSingleValueOfFilter,
        onFilterSelect: applyFilters,
      }}
    >
      {children}
    </ProductListFilterContext.Provider>
  );
};
