import { useCallback, useMemo } from "react";

import { useSearchParams } from "@remix-run/react";

import type { ProductListFilterValue } from "~/commerce-sap/routes/($locale)+/_product-list+/components/filtration/product-list-filter-dialog/product-list-filter-select";

type UseAlgoliaFacetToFilterProps = {};

type UseAlgoliaFacetToFilter = (props?: UseAlgoliaFacetToFilterProps) => {
  selectedFilters: { [key: string]: string[] };
  clearFilters: (forceReload?: boolean) => void;
  clearSelectionOfFilter: (labelName: string, forceReload?: boolean) => void;
  clearSingleValueOfFilter: (labelName: string, valueToRemove: string) => void;
  applyFilters: (
    filterAttributeId: string,
    value: ProductListFilterValue,
  ) => void;
};

const useAlgoliaFacetToFilter: UseAlgoliaFacetToFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const refineQueryParams = searchParams.get("refine");
  const selectedFilters = useMemo(() => {
    const queryRefines = refineQueryParams
      ? JSON.parse(decodeURIComponent(refineQueryParams))
      : [];

    const selectedFiltersObject: { [key: string]: string[] } = {};
    queryRefines.forEach((filterValues: string[]) => {
      filterValues.forEach(filter => {
        const [label, value] = filter.split(":");
        if (!selectedFiltersObject[label]) {
          selectedFiltersObject[label] = [];
        }
        selectedFiltersObject[label].push(value);
      });
    });

    return selectedFiltersObject;
  }, [refineQueryParams]);

  const selectedFiltersToSearchParam = useCallback(
    (filters: { [key: string]: string[] }) => {
      const facetFilters: string[][] = [];

      Object.keys(filters).forEach((key: string) => {
        facetFilters.push(filters[key].map(value => `${key}:${value}`));
      });

      return facetFilters;
    },
    [],
  );

  const setFacetsToUrl = useCallback(
    (filters: { [key: string]: string[] }) => {
      const facetFilters = selectedFiltersToSearchParam(filters);

      setSearchParams(
        prev => {
          if (facetFilters.length === 0) {
            prev.delete("refine");
          } else {
            prev.set("refine", JSON.stringify(facetFilters));
          }

          if (prev.get("page")) {
            prev.delete("page");
          }

          return prev;
        },
        { preventScrollReset: true },
      );
    },
    [selectedFiltersToSearchParam, setSearchParams],
  );

  const applyFilters = useCallback(
    (attributeId: string, value: ProductListFilterValue) => {
      const filters = { ...selectedFilters };

      if (filters[attributeId]) {
        if (filters[attributeId].includes(decodeURIComponent(value.value))) {
          filters[attributeId] = filters[attributeId].filter(
            selectedValue => selectedValue !== decodeURIComponent(value.value),
          );
          if (filters[attributeId].length === 0) {
            delete filters[attributeId];
          }
        } else {
          filters[attributeId].push(value.value);
        }
      } else {
        filters[attributeId] = [value.value];
      }
      setFacetsToUrl(filters);
    },
    [selectedFilters, setFacetsToUrl],
  );

  const clearFilters = useCallback(() => {
    setSearchParams(
      prev => {
        prev.delete("refine");

        return prev;
      },
      { preventScrollReset: true },
    );
  }, [setSearchParams]);

  const clearSingleValueOfFilter = useCallback(
    (filterAttributeId: string, valueToRemove: string) => {
      const filters = { ...selectedFilters };
      if (filters[filterAttributeId]) {
        filters[filterAttributeId] = filters[filterAttributeId].filter(
          selectedValue => selectedValue !== valueToRemove,
        );
        if (filters[filterAttributeId].length === 0) {
          delete filters[filterAttributeId];
        }
      }
      setFacetsToUrl(filters);
    },
    [selectedFilters, setFacetsToUrl],
  );

  const clearSelectionOfFilter = useCallback(
    (labelName: string) => {
      setSearchParams(
        prev => {
          const refineQueryParams = prev.get("refine");
          if (!refineQueryParams) return prev;

          const params =
            JSON.parse(decodeURIComponent(refineQueryParams)) ?? [];

          prev.set(
            "refine",
            JSON.stringify(
              params.filter(
                (param: string[]) => param[0].split(":")[0] !== labelName,
              ),
            ),
          );

          return prev;
        },
        { preventScrollReset: true },
      );
    },
    [setSearchParams],
  );

  return {
    selectedFilters,
    clearFilters,
    clearSelectionOfFilter,
    clearSingleValueOfFilter,
    applyFilters,
  };
};

export default useAlgoliaFacetToFilter;
